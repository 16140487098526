import s from "./price.module.scss";
import { Element } from "react-scroll";

const Price = () => {
  const handleClick = () => {
    window.location.href = "https://www.instagram.com/train.with.vlad/";
  };
  return (
    <Element name="price">
      <section className={s.price}>
        <div className={s.price_container}>
          <h2 className={s.price_title}>Прайс</h2>
          <ul className={s.price_cards}>
            <li className={`${s.price_cards_item} ${s.card_1}`}>
              <h3 className={s.price_cards_item_title}>Онлайн супровід</h3>
              <ul className={s.price_cards_item_list}>
                <li className={s.price_cards_item_list_service}>
                  Ціна формується після консультації
                </li>
                <li className={s.price_cards_item_list_service}>
                  <button
                    onClick={handleClick}
                    className={s.price_cards_item_list_service_btn}
                  >
                    отримати консультацію
                  </button>
                </li>
              </ul>
              <p className={s.price_cards_item_list_text}>
                Контрольний тижневий звіт, програма тренувань та харчування під
                ваші цілі, постійний зв’язок.
              </p>
            </li>
            <li className={`${s.price_cards_item} ${s.card_2}`}>
              <h3 className={s.price_cards_item_title}>Програма тренувань</h3>
              <ul className={s.price_cards_item_list}>
                <li className={s.price_cards_item_list_service}>
                  Програма тренувань - <span>75$ </span>
                </li>
                <li className={s.price_cards_item_list_service}>
                  План харчування - <span>75$</span>
                </li>
                <li className={s.price_cards_item_list_service}>
                  План тренувань та харчування - <span>135$</span>
                </li>
              </ul>
              <p className={s.price_cards_item_list_text}>
                Програма тренувань та харчування - розробляю під ваші цілі, та
                під ваше обладнання для максимального результату.
              </p>
            </li>
            <li className={`${s.price_cards_item} ${s.card_3}`}>
              <h3 className={s.price_cards_item_title}>Стероїдні курси </h3>
              <ul className={s.price_cards_item_list}>
                <li className={s.price_cards_item_list_service}>
                  Стероїдні курси для спортсменів -
                  <span>ціна обговорюється індивідуально</span>
                </li>
              </ul>
              <p className={s.price_cards_item_list_text}>
                Консультація , контроль та перевірка аналізів на курсі. Підбір
                бадів для здоров’я.
              </p>
            </li>
          </ul>
        </div>
      </section>
    </Element>
  );
};

export default Price;
