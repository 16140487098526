import {
  AplleIcon,
  CollIcon,
  ControllIcon,
  GymIcon,
  HandleIcon,
  PhotoIcon,
} from "../Assets/Icons";
import s from "./advantage.module.scss";
import { Element } from "react-scroll";

const Advantage = () => {
  return (
    <Element name="advantage">
      <section className={s.advantage}>
        <div className={s.advantage_wrapper}>
          <h2 className={s.advantage_title}>Онлайн супровід</h2>
          <ul className={s.advantage_cards}>
            <li className={s.advantage_cards_item}>
              <div className={s.advantage_cards_item_up}>
                <ControllIcon />
                <h3 className={s.advantage_cards_item_up_title}>Контроль</h3>
              </div>
              <p className={s.advantage_cards_item_text}>
                Щотижневе звітування та аналіз проробленої роботи по всім
                показникам протягом 7 днів ( заміри м’язів та контроль ваги
                відповідно стандартам вимог та відповідним графікам).
              </p>
            </li>
            <li className={s.advantage_cards_item}>
              <div className={s.advantage_cards_item_up}>
                <GymIcon />
                <h3 className={s.advantage_cards_item_up_title}>
                  Програма тренування
                </h3>
              </div>
              <p className={s.advantage_cards_item_text}>
                Розроблення персональної програми тренування відповідно вашим
                цілям, враховуючи всі фактори впливу. Програма індивідуальна та
                розрахована саме під ваші вимоги, параметри та наявність
                відповідного спортивного обладнання.
              </p>
            </li>
            <li className={s.advantage_cards_item}>
              <div className={s.advantage_cards_item_up}>
                <AplleIcon />
                <h3 className={s.advantage_cards_item_up_title}>
                  Програма харчування
                </h3>
              </div>
              <p className={s.advantage_cards_item_text}>
                Аналіз вашого базового обміну речовин, враховуючи спосіб життя,
                звички та антропометричні показники, алергічні реакції тощо.
                Розраховується з урахуванням вашого продуктового кошику та
                особистих побажань.
              </p>
            </li>
            <li className={s.advantage_cards_item}>
              <div className={s.advantage_cards_item_up}>
                <HandleIcon />
                <h3 className={s.advantage_cards_item_up_title}>
                  Підтримка 24/ 7
                </h3>
              </div>
              <p className={s.advantage_cards_item_text}>
                Максимальна мотивація та постійний зв’язок, регулярне надання
                консультацій відповідно до потреб клієнта. Ми з вами маємо
                спільні цілі та єдиний голос комунікації.
              </p>
            </li>
            <li className={s.advantage_cards_item}>
              <div className={s.advantage_cards_item_up}>
                <PhotoIcon />
                <h3 className={s.advantage_cards_item_up_title}>
                  Контроль виконання вправ
                </h3>
              </div>
              <p className={s.advantage_cards_item_text}>
                Контроль та перевірка виконання фізичних вправ, що допоможе
                систематизувати роботу, уникнути помилок у виконанні фізичних
                навантажень, а також відстеження процесу виконання (формат відео
                запису виконаної роботи)
              </p>
            </li>
            <li className={s.advantage_cards_item}>
              <div className={s.advantage_cards_item_up}>
                <CollIcon />
                <h3 className={s.advantage_cards_item_up_title}>
                  Консультація
                </h3>
              </div>
              <p className={s.advantage_cards_item_text}>
                Check-Up організму відповідно потребам клієнта( перевірка
                комплексних мед. аналізів), підбір бадів, стероїдні курси для
                спортсменів під пильним контролем та багато іншого.
              </p>
            </li>
          </ul>
        </div>
      </section>
    </Element>
  );
};

export default Advantage;
