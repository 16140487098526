import React from "react";
import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import "./style.css";
import s from "./faq.module.scss";
import { Element } from "react-scroll";

const AccordionDemo = () => (
  <Element name="faq">
    <section className={s.container}>
      <div className={s.container_wrapper}>
        <h2 className={s.container_wrapper_title}> FAQ </h2>
        <Accordion.Root
          className="AccordionRoot"
          type="single"
          defaultValue="item-1"
          collapsible
        >
          <Accordion.Item className="AccordionItem" value="item-1">
            <AccordionTrigger>
              Ви працюєте зі звичайними людьми чи тільки із спортсменами ?
            </AccordionTrigger>
            <AccordionContent>
              Я працюю абсолютно з усіма, хто ставить перед собою цілі та хоче
              досягти бажаного результату.
            </AccordionContent>
          </Accordion.Item>

          <Accordion.Item className="AccordionItem" value="item-2">
            <AccordionTrigger>
              За скільки часу я отримаю форма своєї мрії ?
            </AccordionTrigger>
            <AccordionContent>
              Все залежить від вашої дисципліни та від генетичних даних. Але
              будь-яку генетику переможе плідна та сумлінна праця над собою.
            </AccordionContent>
          </Accordion.Item>

          <Accordion.Item className="AccordionItem" value="item-3">
            <AccordionTrigger>
              У плані харчування який Ви розписуєте буде одна гречка та варена
              куряча грудка ?
            </AccordionTrigger>
            <AccordionContent className="AccordionContent">
              Ні! Цей час вже минув і ми працюємо по сучасному. Я розписую план
              харчування під ваші побажання. Можна навіть шоколадку кожен день і
              худнути.
            </AccordionContent>
          </Accordion.Item>
          <Accordion.Item className="AccordionItem" value="item-4">
            <AccordionTrigger>
              Чи є крепатура показником прогресу?
            </AccordionTrigger>
            <AccordionContent className="AccordionContent">
              Ні! Показником прогресу являється результат вашої проробленої
              роботи, а саме збільшення ваших показників таких як набір мʼязової
              тканини.
            </AccordionContent>
          </Accordion.Item>
          <Accordion.Item className="AccordionItem" value="item-5">
            <AccordionTrigger>
              Якщо я буду багато тренувати прес у мене зʼявляться кубики?
            </AccordionTrigger>
            <AccordionContent className="AccordionContent">
              Кубики зʼявляться у випадку якщо ви будете слідувати програмі
              тренування на прес, щоб мʼязи встигали відновлюватися та
              прогресувати.
            </AccordionContent>
          </Accordion.Item>
        </Accordion.Root>
      </div>
    </section>
  </Element>
);

const AccordionTrigger = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Header className="AccordionHeader">
      <Accordion.Trigger
        className={classNames("AccordionTrigger", className)}
        {...props}
        ref={forwardedRef}
      >
        {children}
        <ChevronDownIcon className="AccordionChevron" aria-hidden />
      </Accordion.Trigger>
    </Accordion.Header>
  )
);

const AccordionContent = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
      className={classNames("AccordionContent", className)}
      {...props}
      ref={forwardedRef}
    >
      <div className="AccordionContentText">{children}</div>
    </Accordion.Content>
  )
);

export default AccordionDemo;
